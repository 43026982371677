const navbarAll = "#ffffff"
const darkAll = "#000000"
const secondColorAll = "#f0f0f0"


export const header = "#012a45";
// export const navbar = "#000000";
// export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#ffcd24";
export const dimBackground = "#e6e3e3";
export const lightBackground = darkAll;
export const darkBackground = "#303030";
export const white = "#ffffff";

//theme color for Brand Invoice

export const brandName = "The Student Helpers";
export const brandUrl = "Thestudenthelpers.com";

export const navbar = navbarAll;
export const navbarText = darkAll;
export const lightText = navbarAll;
export const mainBackgound = darkAll;
export const invoiceCard = "#e1e2e6";
export const button = "#005c9a";
export const cardBackground = "#e1e2e6";

